import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { SvgIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import GitHubIcon from "@mui/icons-material/GitHub";
import * as React from "react";

function AvatarLink(url: string, icon: JSX.Element): JSX.Element {
  return (
    <Avatar
      sx={{
        bgcolor: "#e6f5ff",
        padding: 1,
      }}
    >
      <Link href={url} target="_blank">
        {icon}
      </Link>
    </Avatar>
  );
}

export default function AvatarRow(): JSX.Element {
  return (
    <Stack direction="row" spacing={1}>
      {AvatarLink(
        "https://www.linkedin.com/in/swan-toma-41090163/",
        <LinkedInIcon
          fontSize="large"
          htmlColor="black"
          sx={{
            ":hover": {
              fill: "gold",
            },
          }}
        />
      )}
      {AvatarLink(
        "https://medium.com/@swantoma",
        <SvgIcon
          fontSize="large"
          sx={{
            ":hover": {
              fill: "gold",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 50 50"
          >
            <path d="M45,4H5C4.448,4,4,4.448,4,5v40c0,0.552,0.448,1,1,1h40c0.552,0,1-0.448,1-1V5C46,4.448,45.552,4,45,4z M40,13.5 l-1.821,2.197C38.064,15.811,38,15.965,38,16.125V32.75c0,0.16,0.064,0.314,0.179,0.428L40,35.546V36H30v-0.454l2.821-2.368 C32.936,33.064,33,32.91,33,32.75V17.879L24.848,36h-0.001h-1.543l0,0L15,18.375v13.108c0,0.22,0.076,0.433,0.215,0.605L18,35.546 V36h-8v-0.454l2.783-3.438C12.923,31.936,13,31.721,13,31.5V16.388c0-0.142-0.05-0.279-0.142-0.388L11,13.5V13h7.097l7.624,16.183 L33.002,13H40V13.5z"></path>
          </svg>
        </SvgIcon>
      )}
      {AvatarLink(
        "https://github.com/Swan-Toma",
        <GitHubIcon
          htmlColor="black"
          fontSize="large"
          sx={{
            ":hover": {
              fill: "gold",
            },
          }}
        />
      )}
      {AvatarLink(
        "https://stackoverflow.com/users/9747747/swan-toma",
        <SvgIcon
          fontSize="large"
          sx={{
            ":hover": {
              fill: "gold",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 50 50"
          >
            <path
              fillRule="evenodd"
              d="M 40.925781 1.890625 L 37.859375 2.417969 L 41.1875 20.625 L 44.03125 20.253906 Z M 29.96875 6.351563 L 27.101563 8.078125 L 37.300781 23.035156 L 39.820313 21.480469 Z M 20.796875 15.03125 L 19.113281 17.703125 L 34.5 27 L 35.902344 24.578125 Z M 16.375 24.402344 L 15.628906 27.402344 L 33.359375 31.894531 L 33.640625 29.203125 Z M 9 29 L 9 47.984375 L 38.902344 48 L 38.902344 47.984375 C 38.933594 47.984375 39 29 39 29 L 36 29 L 36 45 L 12 45 L 12 29 Z M 15.152344 32.355469 L 14.902344 35.339844 L 33 37 L 33.203125 34.5 Z M 14.902344 39 L 15 42 L 33 41.929688 L 33 39 Z"
            ></path>
          </svg>
        </SvgIcon>
      )}
    </Stack>
  );
}
