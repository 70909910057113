import { Avatar } from "@mui/material";
import React from "react";
import swantoma from "./swantoma.jpg";

const gradient = `
linear-gradient(180deg,
    hsl(105deg 25% 28%) 0%,
    hsl(99deg 30% 32%) 6%,
    hsl(92deg 34% 35%) 13%,
    hsl(85deg 39% 38%) 19%,
    hsl(79deg 44% 42%) 25%,
    hsl(72deg 49% 45%) 31%,
    hsl(65deg 53% 48%) 37%,
    hsl(59deg 58% 52%) 44%,
    hsl(52deg 62% 55%) 50%,
    hsl(46deg 67% 59%) 56%,
    hsl(39deg 72% 62%) 63%,
    hsl(33deg 76% 65%) 69%,
    hsl(26deg 81% 69%) 75%,
    hsl(20deg 86% 72%) 81%,
    hsl(13deg 90% 75%) 87%,
    hsl(6deg 94% 79%) 94%,
    hsl(0deg 100% 82%) 100%)`;

export function Picture(): JSX.Element {
  return (
    <div
      style={{
        backgroundImage: gradient,
        borderRadius: "50%",
        display: "flex",
        height: "315px",
        justifyContent: "center",
        alignItems: "center",
        // position: "relative",
        // textAlign: "center",
        width: "315px",
      }}
    >
      <Avatar
        alt="Swan Toma"
        src={swantoma}
        sx={{
          height: 300,
          // position: "absolute",
          margin: 0,
          width: 300,
        }}
      />
    </div>
  );
}
