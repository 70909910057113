import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Main from "components/Common/Main";
import PersistentDrawerLeft from "components/Drawer/PersistentDrawerLeft";
import * as React from "react";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import { Label } from "@mui/icons-material";
import Stack from "@mui/material/Stack";

interface Book {
  author: string;
  title: string;
}

interface Article {
  author: string;
  link: string;
  note: string;
  tags: string[];
  related_books?: Book[];
  title: string;
}

const data = require("./articles.json");

export default function Articles(): JSX.Element {
  const articles: Article[] = [...data];
  const tags = new Set<string>();
  const [selectedTags, setSelectedTags] = React.useState<Set<string>>(
    new Set()
  );

  articles.sort((article1, article2) =>
    article1.title.localeCompare(article2.title)
  );

  articles.forEach((article) => article.tags.forEach((tag) => tags.add(tag)));

  return (
    <>
      <PersistentDrawerLeft />
      <Stack direction="row" spacing={1} sx={{ ml: 10 }}>
        {[...tags].map((tag, i) => (
          <Chip
            color={selectedTags.has(tag) ? "success" : "default"}
            key={i}
            label={tag}
            variant="filled"
            onClick={() => {
              if (selectedTags.has(tag)) {
                setSelectedTags((current) => {
                  current.delete(tag);
                  return new Set(current);
                });
              } else {
                setSelectedTags((current) => {
                  current.add(tag);
                  return new Set(current);
                });
              }
            }}
          />
        ))}
      </Stack>
      <Main>
        <Grid container spacing={5} ml={4} mr={4}>
          {articles.map((article) => (
            <Grid item xs={12} sm={6} md={3} key={article.link}>
              <Card
                sx={{
                  minWidth: 200,
                  minHeight: 200,
                  p: 2,
                  backgroundColor: "#c6d6c1",
                }}
              >
                <Typography variant="h5">{article.title}</Typography>
                <Typography variant="h6">By {article.author}</Typography>
                <br />
                <Typography>{article.note}</Typography>
                <br />
                <Link
                  href={article.link}
                  underline="always"
                  target="_blank"
                  rel="noopener"
                >
                  Read More
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Main>
    </>
  );
}
