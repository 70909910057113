import { styled } from "@mui/material/styles";
import * as React from "react";

interface MainTagProps {
  open?: boolean;
}

const styledOptions = {
  shouldForwardProp: (prop: PropertyKey) => prop !== "open",
};

const MainStyled = styled(
  "main",
  styledOptions
)<MainTagProps>(({ theme, open }) => ({
  fontFamily: "Merriweather, serif",
  fontWeight: 400,
  fontStyle: "normal",
  display: "flex",
  justifyContent: "center",
  marginTop: 45,
  // marginLeft: 50,
  //   transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   ...(open && {
  //     transition: theme.transitions.create("margin", {
  //       easing: theme.transitions.easing.easeOut,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //     marginLeft: 0,
  //   }),
}));

interface Props {
  children: JSX.Element;
}

export default function Main({ children }: Props): JSX.Element {
  return <MainStyled>{children}</MainStyled>;
}
