import ArticleIcon from "@mui/icons-material/Article";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const drawerWidth = 200;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function getListItem(text: string, path: string, icon: JSX.Element) {
  const navigate = useNavigate();

  return (
    <ListItem
      key={text}
      disablePadding
      style={{ color: "white" }}
      sx={{
        // hover states (https://stackoverflow.com/questions/61486061/how-to-set-selected-and-hover-color-of-listitem-in-material-ui)
        "& .MuiListItemButton-root:hover": {
          bgcolor: "#22311d",
        },
      }}
    >
      <ListItemButton onClick={() => navigate(path, { replace: true })}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

export default function PersistentDrawerLeft() {
  // Theme
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.up("md"));

  // Drawer State
  const [open, setOpen] = React.useState(medium ? true : false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleDrawerOpen}
        sx={{
          color: "#730000",
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#304529",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon sx={{ color: "white" }} />
          </IconButton>
        </DrawerHeader>
        <List>
          <Divider sx={{ color: "white", mt: 2, mb: 1, fontSize: "small" }}>
            *
          </Divider>
          {/* Main Section */}
          {getListItem(
            "About Me",
            "/",
            <HomeIcon style={{ color: "white" }} />
          )}
          {getListItem(
            "Resume",
            "../resume",
            <FilePresentIcon style={{ color: "white" }} />
          )}
          {/* Library Section */}
          <Divider sx={{ color: "white", mt: 2, mb: 1, fontSize: "small" }}>
            Library
          </Divider>
          {getListItem(
            "Articles",
            "../articles",
            <ArticleIcon style={{ color: "white" }} />
          )}
          {getListItem(
            "Quotes",
            "../quotes",
            <FormatQuoteIcon style={{ color: "white" }} />
          )}
        </List>
      </Drawer>
    </>
  );
}
