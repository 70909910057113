import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import AboutMe from "components/AboutMe/AboutMe";
import Articles from "components/Articles/Articles";
import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

export function App() {
  // Theme
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.up("md"));

  // Drawer State
  const [open, setOpen] = React.useState(medium ? true : false);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<AboutMe />} />
        <Route path="articles" element={<Articles />} />
        <Route path="resume" element={<AboutMe />} />
        <Route path="quotes" element={<AboutMe />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
