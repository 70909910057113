[
    {
        "author": "James Somers",
        "link": "https://www.newyorker.com/magazine/2018/12/10/the-friendship-that-made-google-huge",
        "note": "A great story on the power of collaboration and a friendship that made Google great.",
        "related_books": [
            {
                "author": "Joshua Wolf Shenk",
                "title": "Powers of Two: Finding the Essence of Innovation in Creative Pairs"
            },
            {
                "author": "Michael Lewis",
                "title": "The Undoing Project: A Friendship That Changed Our Minds"
            }
        ],
        "tags": ["inspiring", "programming"],
        "title": "The Friendship That Made Google Huge"
    },
    {
        "author": "Manuel Blum",
        "link": "https://www.cs.cmu.edu/~mblum/research/pdf/grad.html",
        "note": "Most of us won't attend graduate school, but you'll find inspiration here about learning how to learn and how to ask better ask questions.",
        "tags": ["inspiring", "learning"],
        "title": "Advice to a Beginning Graduate Student"
    }
]