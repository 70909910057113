import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Picture } from "components/AboutMe/Picture/Picture";
import * as React from "react";
import PersistentDrawerLeft from "../Drawer/PersistentDrawerLeft";
import AvatarRow from "./AvatarRow";
import Main from "components/Common/Main";

const PARAGRAPH_STYLES: React.CSSProperties = {
  maxWidth: 350,
  lineHeight: 1.7,
};

export default function AboutMe() {
  return (
    <>
      <PersistentDrawerLeft />
      <Main>
        <Stack
          spacing={3}
          display="flex"
          alignItems="center"
          width="100%"
          padding={2}
        >
          <Picture />
          <AvatarRow />
          <Box>
            <Typography variant="h6" sx={{ ...PARAGRAPH_STYLES }}>
              Hello 👋 my name is{" "}
              <span style={{ fontWeight: "bold" }}>Swan Toma</span>. I'm a
              Software Engineer located in San Diego, CA. My experience covers
              front-end web development, back-end (APIs, services, databases),
              and infrastructure (AWS).
            </Typography>
            <br />
            <Typography variant="h6" sx={{ ...PARAGRAPH_STYLES }}>
              I share articles and quotes that I love in the library section if
              you're interested.
            </Typography>
            <br />
            <Typography variant="h6" sx={{ ...PARAGRAPH_STYLES }}>
              The code for the website can be found in my Github. It's made
              using React + Material UI.
            </Typography>
            <br />
            <Typography variant="h6" sx={{ ...PARAGRAPH_STYLES }}>
              Thank you for visting!
            </Typography>
          </Box>
        </Stack>
      </Main>
    </>
  );
}
